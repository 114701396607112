import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from '../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'
import banner_img from '../assets/images/projects/project2.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'Custom Art',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'Business to Customer transactions',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Business to Business transactions',
    },
    {
        id: 3,
        className: 'opensearch',
        label: 'AI & Conversational',
    },
];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"Mboma Gallery"}
                location={"Johanessburg, South Africa"}
                technologies={"React, Typescript, MongoDB, Paystack"}
                completed={"30 September 2022"}
                website={"https://mbomagallery.com"}
                type={"eCommerce Art Gallery"}
                title={"Mboma Art Gallery"}
                description={"Online eCommerce platform connecting artists in Africa. The platform is used to facilitate transactions between B2B and B2C."}
                important_facts={""}
                long_description1={"The Mboma gallery project was developed with the main goal of putting SA art talent online. The gallery is currently a Business to Customer offering with integrated payment functionality for local and international transactions."}
                long_description2={"Mboma Gallery and theParse are building a community of art lovers who will participate on Mboma Gallery and use it as a platform to market and sell content to other artists and business in the art eco-system."}
                results={"The project was first launched in 2022 and continues to grow."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails